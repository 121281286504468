<template>
    <div id="app">
        <!-- <label for="locale">locale</label>
        <select v-model="locale">
            <option>en</option>
            <option>ja</option>
        </select>
        <p>message: {{ $t("hello") }}</p> -->
    </div>
</template>
<script>
export default {
    name: "Service",
    data() {
        this.$i18n.locale = "en";
        return { locale: "en" };
    },
    watch: {
        locale(val) {
            this.$i18n.locale = val;
        }
    },
    
};
</script>

<i18n>
{
  "en": {
    "hello": "hello world!666"
  },
  "ja": {
    "hello": "こんにちは、世界！666"
  }
}
</i18n>